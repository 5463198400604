<template>
<div class="tabulation"  :style="{overflow:cat}" v-loading="loading" element-loading-text="加载中" element-loading-background="rgb(255, 255, 255, 0.5)">
  <el-card>
    <div style="margin-top: 20px;">
      <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
      <!-- 标题 -->
        <el-form-item label="分类名称" prop="name">
          <div class="aaaa">
            <el-input v-model="form.name"  placeholder="请填写分类名称" size="large"></el-input>
          </div>
        </el-form-item>
      <!-- 是否发布 -->
       <el-form-item label="是否发布" prop="are">
          <el-select v-model="form.are" placeholder="请选择是否发布" size="large">
            <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
        </el-form-item>


        <el-form-item>
          <div class="submit">
            <el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading" @click="onSubmit">提交</el-button>
          </div>
        </el-form-item>
    </el-form>
    </div>
  
  </el-card>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, onMounted, reactive,onActivated } from 'vue'
// import {  Plus,} from '@element-plus/icons-vue'
// import * as imageConversion from 'image-conversion'
import { EditClassify,SeaveClassify } from '../../../utils/api'
import qs from 'qs'
import { ElMessage } from "element-plus";
import { useRouter,useRoute } from "vue-router";
export default{
  name:"editclassify",
  components:{  },
  setup(){
    const route = useRoute();
    // 加载框
    let loading=ref(true)
    // 按钮加载
    const ConfirmLoading=ref(false)
    // 提交 按钮
    const router = useRouter();
    const onSubmit =()=>{
      ruleFormRef.value.validate((valid)=>{
        if(valid==true){
          ConfirmLoading.value=true
          let deny=ref(null)
          if(form.are=='是'){
            deny.value=1
          }else if(form.are=='否'){
            deny.value=2
          }else if(form.are==1){
            deny.value=1
          }else if(form.are==2){
            deny.value=2
          }
          let data = {
            name:form.name,
            classifyId:sessionStorage.getItem('classifyId'),
            status:deny.value
          }
          SeaveClassify(qs.parse(data)).then((res)=>{
            console.log(res)
            if(res.data.code==0){
              ElMessage({
                message: "编辑成功",
                type: "success",
                center: true,
              });
              setTimeout(()=>{
                  router.push({ name: 'classify', params: { userId: 1 }});
                  ConfirmLoading.value=false
                },500)
              // router.push("/classify")
              // ConfirmLoading.value=false
            }
          })
        }else{
          ElMessage({
            message: "提交失败",
            type: "error",
            center: true,
          });
          ConfirmLoading.value=false
        }
        console.log(valid)
        console.log(form)
      })
    }
    // 获取编辑信息
    let list =()=>{
      let data={
        classifyId:sessionStorage.getItem('classifyId')
      }
      EditClassify(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          loading.value=false
          form.name=res.data.data.classifyInfo.name
          if(res.data.data.classifyInfo.status==1){
            form.are='是'
          }else if(res.data.data.classifyInfo.status==2){
            form.are='否'
          }
        }
      })
    }
    // 模拟 ajax 异步获取内容
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.UserId
      if(translate.value==1){
        loading.value=true
        list()
      }
    })
    onMounted(() => {
      list()
    })
 

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
    })
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      breviary:"",
      rank:""
    })
    // 验证表单提示
    const rules ={
      name:[
        { required: true, message: "请填写类型名称", trigger: "blur" },
      ],
      are:[
        { required: true, message: "请选择是否发布", trigger: "blur" },
      ],
    }
    return{
      loading,
      // 按钮加载
      ConfirmLoading,
      // handlePictureCardPreview,
      // 编辑器
      onSubmit,
      // 标题验证
      ruleFormRef,
      form,
      rules
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .submit{
  margin-left: 80px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
}
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .el-form-item__content{
  align-items: flex-end;
}
::v-deep .referrals{
  color: #cccccc;
  margin-left: 5px;
}
::v-deep .styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
::v-deep .styC .el-upload--picture-card{
  display:none;   
}
::v-deep .el-form-item{
  align-items: center;
}
.aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
::v-deep .el-card.is-always-shadow{
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: auto;
  margin-top: 10px;
}
::v-deep .tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 80px !important;
  font-weight: bold;
}
</style>
